// This file is part of the Cloudillo Platform.
// Copyright (C) 2024  Szilárd Hajba
//
// Cloudillo is free software: you can redistribute it and/or modify
// it under the terms of the GNU Lesser General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Lesser General Public License for more details.
//
// You should have received a copy of the GNU Lesser General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LuX as IcDelete } from 'react-icons/lu';
import { Select } from './select.js';
import { ProfileCard } from './components.js';
export function EditProfileList({ profiles, listProfiles, addProfile, removeProfile }) {
    const [add, setAdd] = React.useState(false);
    const { t } = useTranslation();
    async function getData(q) {
        console.log('getData', q);
        if (!q)
            return [];
        const list = await listProfiles(q);
        return list;
    }
    function renderItem(profile) {
        return React.createElement(ProfileCard, { profile: profile });
    }
    function onAdd(profile) {
        return profile && addProfile?.(profile);
    }
    function onRemove(profile) {
        return profile && removeProfile?.(profile.idTag);
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Select, { placeholder: t('Search user'), getData: getData, itemToId: i => i.idTag, itemToString: i => i?.idTag || '', renderItem: renderItem, onSelectItem: onAdd }),
        React.createElement("div", null, (profiles || []).map((profile, i) => (React.createElement("button", { key: profile.idTag, className: "c-link w-100 p-0 ps-1", onClick: () => onRemove(profile) },
            React.createElement(ProfileCard, { className: "w-100", profile: profile }),
            React.createElement(IcDelete, null))))));
}
// vim: ts=4
