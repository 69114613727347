// This file is part of the Cloudillo Platform.
// Copyright (C) 2024  Szilárd Hajba
//
// Cloudillo is free software: you can redistribute it and/or modify
// it under the terms of the GNU Lesser General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Lesser General Public License for more details.
//
// You should have received a copy of the GNU Lesser General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useAtom } from 'jotai';
import { Button, mergeClasses } from './components.js';
import { LuX as IcClose } from 'react-icons/lu';
export function Dialog({ className, open, children }) {
    const ref = React.useRef(null);
    React.useEffect(function () {
        if (ref.current) {
            if (open) {
                ref.current.showModal();
            }
            else {
                ref.current.close();
            }
        }
    }, [open]);
    return React.createElement("dialog", { ref: ref, className: className }, children);
}
const dialogAtom = atom();
let dialogResolve;
export function DialogContainer() {
    const { t } = useTranslation();
    const [dialog, setDialog] = useAtom(dialogAtom);
    const [value, setValue] = React.useState('');
    function onButtonClick(value) {
        setDialog(undefined);
        dialogResolve && dialogResolve(value);
    }
    function onCancel() {
        setDialog(undefined);
        dialogResolve && dialogResolve(undefined);
    }
    return React.createElement(React.Fragment, null, dialog && React.createElement("div", { className: mergeClasses('c-modal', dialog.className, dialog && 'show'), tabIndex: -1 }, dialog && React.createElement("div", { className: "c-panel emph p-4" },
        React.createElement("div", { className: "c-hbox" },
            React.createElement("h2", { className: "fill" }, dialog.title),
            React.createElement("button", { type: "button", className: "c-link", "data-bs-dismiss": "modal", "aria-label": "Close", onClick: onCancel },
                React.createElement(IcClose, null))),
        React.createElement("p", { className: "mt-4" }, dialog.descr),
        dialog.type == 'Tell' && React.createElement("div", { className: "c-group g-2 mt-4" },
            React.createElement(Button, { primary: true, onClick: () => onButtonClick(true) }, t('OK'))),
        dialog.type == 'OkCancel' && React.createElement("div", { className: "c-group g-2 mt-4" },
            React.createElement(Button, { primary: true, onClick: () => onButtonClick(true) }, t('OK')),
            React.createElement(Button, { onClick: onCancel }, t('Cancel'))),
        dialog.type == 'YesNo' && React.createElement("div", { className: "c-group g-2 mt-4" },
            React.createElement(Button, { primary: true, onClick: () => onButtonClick(true) }, t('Yes')),
            React.createElement(Button, { onClick: () => onButtonClick(false) }, t('No'))),
        dialog.type == 'Text' && React.createElement(React.Fragment, null,
            React.createElement("form", { onSubmit: e => { e.preventDefault(); onButtonClick(value); } },
                React.createElement("input", { className: "c-input mt-3", type: "text", placeholder: dialog.placeholder, defaultValue: dialog.defaultValue, autoFocus: true, onChange: e => setValue(e.target.value) })),
            React.createElement("div", { className: "c-group g-2 mt-4" },
                React.createElement(Button, { primary: true, onClick: () => onButtonClick(true) }, t('OK')),
                React.createElement(Button, { onClick: onCancel }, t('Cancel')))))));
}
export function useDialog() {
    const [_, setDialog] = useAtom(dialogAtom);
    function ret() {
        return new Promise(function (resolve) {
            dialogResolve = resolve;
        });
    }
    function tell(title, descr, className = undefined) {
        setDialog({ type: 'Tell', title, descr, className });
        return ret();
    }
    function confirm(title, descr, className = undefined) {
        setDialog({ type: 'OkCancel', title, descr, className });
        return ret();
    }
    function ask(title, descr, className = undefined) {
        setDialog({ type: 'YesNo', title, descr, className });
        return ret();
    }
    function askText(title, descr, { className, placeholder, defaultValue } = {}) {
        setDialog({ type: 'Text', title, descr, className, placeholder, defaultValue });
        console.log('askText', { title, descr, className, placeholder, defaultValue });
        return ret();
    }
    /*
    function askText(title: string, descr: string, opts: {
        className?: string
        placeholder?: string
        defaultValue?: string
    } = {}) {
        setDialog({ type: 'Text', title, descr })
        console.log('askText', opts, { title, descr })
        return ret()
    }
    */
    return {
        tell,
        confirm,
        ask,
        askText
    };
}
// vim: ts=4
