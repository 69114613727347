// This file is part of the Cloudillo Platform.
// Copyright (C) 2024  Szilárd Hajba
//
// Cloudillo is free software: you can redistribute it and/or modify
// it under the terms of the GNU Lesser General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Lesser General Public License for more details.
//
// You should have received a copy of the GNU Lesser General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useCombobox } from 'downshift';
import debounce from 'debounce';
export function Select({ className, inputClassName, placeholder, getData, onChange, onSelectItem, itemToId, itemToString, renderItem }) {
    const { t } = useTranslation();
    const [popperRef, setPopperRef] = React.useState(null);
    const [popperEl, setPopperEl] = React.useState(null);
    const [items, setItems] = React.useState([]);
    const deboucedOnInputValueChange = React.useCallback(debounce(async function onInputValueChange({ inputValue }) {
        const data = await getData(inputValue || '');
        console.log('onInputValueChange', inputValue, data);
        setItems(data || []);
    }, 500), []);
    let tmpRef;
    const { styles: popperStyles, attributes } = usePopper(popperRef, popperEl, {
        placement: 'bottom-start',
        strategy: 'fixed'
    });
    const s = useCombobox({
        onInputValueChange: arg => deboucedOnInputValueChange(arg),
        items,
        itemToString,
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem)
                onChange?.(selectedItem);
            onSelectItem?.(selectedItem || undefined);
            console.log('onSelectedItemChange', selectedItem);
            s.setInputValue('');
        }
    });
    React.useEffect(function effect() {
        return function cleanup() {
            console.log('cleanup');
            deboucedOnInputValueChange.clear();
        };
    }, []);
    function getMenuProps() {
        const props = s.getMenuProps();
        return {
            ...props,
            ref: (el) => {
                //console.log('getMenuProps', el)
                setPopperEl(el);
                //if (props.ref) (props.ref as React.MutableRefObject<HTMLUListElement>).current = el
                if (props.ref) {
                    if (typeof props.ref === 'function') {
                        props.ref(el);
                    }
                    else {
                        props.ref.current = el;
                    }
                }
            }
        };
    }
    //return <div className={'c-dropdown ' + (className || '')}>
    return React.createElement("div", { className: className },
        React.createElement("div", { ref: setPopperRef },
            React.createElement("input", { className: 'c-input ' + (inputClassName || ''), autoFocus: true, placeholder: placeholder, ...s.getInputProps() })),
        createPortal(React.createElement("ul", { ...getMenuProps(), style: { ...popperStyles.popper, ...(items.length ? {} : { display: 'none' }) }, className: "c-nav flex-column", ...attributes.popper }, items.map((item, idx) => React.createElement("li", { key: idx, className: 'c-nav-item' + (s.highlightedIndex === idx ? ' selected' : ''), ...s.getItemProps({ item, idx }) }, renderItem(item)))), document.getElementById('popper-container')));
    //</details>
}
// vim: ts=4
